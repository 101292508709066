<template>
  <b-card>
    <!-- form -->
    <b-form>
      <b-row>
        <!-- bio -->
        <b-col cols="12">
          <b-form-group
            label="Description de l'Entité/Entreprise"
            label-for="description"
          >
            <b-form-textarea
              id="description"
              v-model="description"
              rows="4"
              placeholder="Vous pouvez vous exprimer ici..."
            />
          </b-form-group>
        </b-col>
        <!--/ bio -->

        <!-- bio -->
        <b-col cols="12">
          <b-form-group label="Slogan de l'Entité" label-for="slogan">
            <b-form-textarea
              id="slogan"
              v-model="slogan"
              rows="4"
              placeholder="slogan de votre Entité..."
            />
          </b-form-group>
        </b-col>
        <!--/ bio -->

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click="postCorporationSettingInformationForm"
          >
            Enregistrer
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mt-1"
            variant="outline-secondary"
            @click.prevent="resetForm"
          >
            Annuler
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";

// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";
import { $themeConfig } from "@themeConfig";
import axios from "axios";
const userData = JSON.parse(localStorage.getItem("userData"));

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    vSelect,
    flatPickr,
    Cleave,
  },
  directives: {
    Ripple,
  },
  props: {
    informationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      countryOption: ["USA", "India", "Canada"],
      localOptions: JSON.parse(JSON.stringify(this.informationData)),
      sku_corporation: userData.sku_corporation,
      description: JSON.parse(JSON.stringify(this.informationData)).description,
      slogan: JSON.parse(JSON.stringify(this.informationData)).slogan,
      clevePhone: {
        phone: true,
        phoneRegionCode: "US",
      },
      errors: [],
    };
  },
  methods: {
    async postCorporationSettingInformationForm() {
      // for the web app //
      let formData = new FormData();
      formData.append("sku_corporation", this.sku_corporation);
      formData.append("description", this.description);
      formData.append("slogan", this.slogan);
      //
      await axios
        .post("update_corporation_infos", formData)
        .then((response) => {
          console.log(response);
          if (response.status == 200) {
            this.$swal({
              title: "Fin de l'opération",
              text: response.data.message,
              icon: "success",
              timer: 10000,
              showCancelButton: true,
              confirmButtonText: "Continuer",
              cancelButtonText: "Annuler",
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-warning ml-1",
              },
              buttonsStyling: false,
            });
          } else {
            this.$swal({
              title: "Erreur Survenu",
              text: response.data.message,
              icon: "error",
              timer: 10000,
              showCancelButton: false,
              confirmButtonText: "Continuer",
              cancelButtonText: "Annuler",
              customClass: {
                confirmButton: "btn btn-warning",
                cancelButton: "btn btn-outline-error ml-1",
              },
              buttonsStyling: false,
            });
          }
        })
        .catch((error) => {
          this.$swal({
            title: "Erreur Survenu",
            text: error.message,
            icon: "error",
            timer: 10000,
            showCancelButton: false,
            confirmButtonText: "Continuer",
            cancelButtonText: "Annuler",
            customClass: {
              confirmButton: "btn btn-warning",
              cancelButton: "btn btn-outline-error ml-1",
            },
            buttonsStyling: false,
          });
        });
    },
    resetForm() {
      this.localOptions = JSON.parse(JSON.stringify(this.informationData));
    },
  },
};
</script>

<style lang="scss">
// @import '@core/scss/vue/libs/vue-select.scss';
// @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
